import { createSlice } from '@reduxjs/toolkit';

import { fetchSettings, togglePreloading } from './setting';
import { request } from '../../services/http';
import { USER_API } from '../../services/api';
import { setOffices } from './election';

export const fetchAuthUser = () => async dispatch => {
	try {
		const rs = await request(USER_API);
		const { user } = rs.result;
		dispatch(loginUser(user));
		dispatch(setOffices(user.offices));
		dispatch(togglePreloading(false));
		dispatch(fetchSettings());
	} catch (e) {
		dispatch(signOut());
		dispatch(togglePreloading(false));
	}
};

const initialState = {
	account: null,
	loggedIn: false,
};

export const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		loginUser: (state, action) => {
			state.account = action.payload;
			state.loggedIn = true;
		},
		setUser: (state, action) => {
			state.account = action.payload;
		},
		signOut: () => {
			return initialState;
		},
	},
});

export const { loginUser, setUser, signOut } = accountSlice.actions;

export default accountSlice.reducer;
