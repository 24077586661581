import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Preloader from './views/Preloader';
import AuthProvider from './provider/AuthProvider';
import AppRoutes from './routes';

const App = () => {
	const preloading = useSelector(state => state.setting.preloading);
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [location]);

	return preloading ? (
		<Preloader />
	) : (
		<AuthProvider>
			<AppRoutes />
		</AuthProvider>
	);
};

export default App;
