import { NOT_VISITED, TOKEN_COOKIE } from './constants';
import LocalStorage from './storage';

const checkStatus = async response => {
	if (!response.ok) {
		if (response.statusText?.toString()?.toUpperCase() === 'UNAUTHORIZED') {
			// prettier-ignore
			const token = (new LocalStorage()).getItem(TOKEN_COOKIE);
			if (token) {
				// prettier-ignore
				(new LocalStorage()).removeItem(TOKEN_COOKIE);
				// prettier-ignore
				(new LocalStorage()).removeItem(NOT_VISITED);

				window.location.reload();
			}
		}
		const message = await response.text();
		const err = JSON.parse(message);
		throw Object.freeze({ message: err.message || err.error || err });
	}

	return response;
};

const parseJSON = response => response.json();

export async function request(uri, { body, ...customConfig } = {}) {
	let headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	// prettier-ignore
	const token = (new LocalStorage()).getItem(TOKEN_COOKIE);

	if (token) {
		const jwt = `Bearer ${token}`;
		headers = customConfig.uploader
			? { Authorization: jwt }
			: { ...headers, Authorization: jwt };
	}

	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: { ...headers },
	};

	if (body) {
		config.body = customConfig.uploader ? body : JSON.stringify(body);
	}

	const response = await fetch(uri, config);
	const result = await checkStatus(response);

	return parseJSON(result);
}
