import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

const CheckLoggedIn = ({ children }) => {
	const { user } = useAuth();
	const location = useLocation();

	const path = location.pathname.split('/').pop();

	if (user && path === 'login' && user.set_password === 1) {
		return <Navigate to="/" state={{ from: location }} replace />;
	}

	return <>{children}</>;
};

export default CheckLoggedIn;
