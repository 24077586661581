import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import notFound from '../assets/images/not-found.svg';

const NoMatch = () => {
	useEffect(() => {
		document.body.classList.add('bg-light');

		return () => {
			document.body.classList.remove('bg-light');
		};
	}, []);

	return (
		<div id="wrapper">
			<div className="section p-0">
				<div className="container text-center">
					<div className="w-100 max-w-500 d-inline-block mt-5 bg-white p-4 p-lg-5 rounded-xl shadow-xs">
						<h1 className="my-5 fw-bold">
							<span className="d-block mb-4">
								Sorry, this page does not exists!
							</span>
							<Link className="btn btn-sm btn-light btn-pill" to="/">
								<i className="fi fi-arrow-start-slim"></i>
								Back to homepage
							</Link>
						</h1>
						<img
							className="opacity-6 max-w-450 img-fluid"
							src={notFound}
							alt="..."
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NoMatch;
