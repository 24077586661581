import settingReducer from './setting';
import userReducer from './user';
import electionReducer from './election';

const reducers = {
	setting: settingReducer,
	user: userReducer,
	election: electionReducer,
};

export default reducers;
