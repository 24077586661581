import notification from 'antd/es/notification';
import { SyncOutlined } from '@ant-design/icons';
import { confirmAlert } from 'react-confirm-alert';

export const isUnset = o => typeof o === 'undefined' || o === null;

export function encodeValue(val) {
	if (typeof val === 'string') {
		return val;
	}

	return JSON.stringify(val);
}

export function decodeValue(val) {
	if (typeof val === 'string') {
		try {
			return JSON.parse(val);
		} catch (_) {}
	}

	return val;
}

export function updateImmutable(list, payload) {
	const data = list.find(d => d.id === payload.id);
	if (data) {
		const index = list.findIndex(d => d.id === payload.id);

		return [
			...list.slice(0, index),
			{ ...data, ...payload },
			...list.slice(index + 1),
		];
	}

	return list;
}

export const error = meta => (meta.touched && meta.error ? 'is-invalid' : '');

export function resetForm(values, form) {
	Object.keys(values).forEach(key => {
		form.change(key, undefined);
		form.resetFieldState(key);
	});
}

export const notifyWithIcon = (type, description, duration = 4.5) => {
	notification[type]({ message: type, description, duration });
};

export const antIconSync = <SyncOutlined style={{ fontSize: 24 }} spin />;

export function confirmAction(action, payload, type, alertText, alertHead) {
	const icons = {
		confirm: 'usownftb',
	};

	confirmAlert({
		customUI: ({ onClose }) => {
			const onClick = async () => {
				action(payload);
				onClose();
			};

			return (
				<div className="custom-ui p-5 text-center">
					{icons[type] && (
						<lord-icon
							src={`https://cdn.lordicon.com/${icons[type]}.json`}
							trigger="loop"
							colors="primary:#0ab39c,secondary:#f06548"
							style={{ width: '100px', height: '100px' }}></lord-icon>
					)}
					<div className="mt-4 text-center">
						<h4>{alertHead ? alertHead : 'Are you sure?'}</h4>
						<p className="text-muted fs-15 mb-4">
							{alertText ? alertText : 'You want to delete this item'}
						</p>
						<div className="hstack gap-2 justify-content-center remove">
							<button className="btn btn-danger" onClick={onClose}>
								No
							</button>
							<button className="btn btn-success text-white" onClick={onClick}>
								Yes
							</button>
						</div>
					</div>
				</div>
			);
		},
	});
}

export const getSetting = (settings, slug) =>
	settings.find(s => s.slug === slug);
