import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { APP_NAME } from '../services/constants';
import { useSelector } from 'react-redux';

const GuestLayout = ({ children }) => {
	const location = useLocation();
	const isSetPassword = useMemo(
		() => location.pathname.split('/').pop() === 'set-password',
		[location.pathname]
	);
	const forgotPassword = useMemo(
		() => location.pathname.split('/').pop() === 'forgot-password',
		[location.pathname]
	);
	const resetPassword = useMemo(() => {
		const splits = location.pathname.split('/');
		if (splits.length > 1) {
			return splits[1] === 'reset-password';
		}
		return false;
	}, [location.pathname]);

	const checking = useSelector(state => state.setting.checking);

	return (
		<div id="wrapper">
			<div
				className="d-lg-flex text-white min-vh-100"
				style={{ background: 'linear-gradient(180deg,#253049 0,#161a24)' }}>
				<div className="col-12 col-lg-5 d-lg-flex">
					<div className="w-100 align-self-center">
						<div className="py-7 px-5">
							<h1 className="d-inline-block text-align-end text-center-md text-center-xs display-5 w-100 max-w-600">
								{resetPassword && 'Reset password'}
								{forgotPassword && 'Reset password'}
								{isSetPassword && 'Set password on'}
								{!forgotPassword &&
									!isSetPassword &&
									!resetPassword &&
									'Sign in to'}
								<span className="display-4 d-block fw-medium">{APP_NAME}</span>
							</h1>
						</div>
					</div>
				</div>
				<div
					className={
						resetPassword && checking
							? 'col-12 col-lg-7 d-lg-flex align-items-center justify-content-center'
							: 'col-12 col-lg-7 d-lg-flex'
					}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default GuestLayout;
