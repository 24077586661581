import React from 'react';

import { APP_NAME } from '../services/constants';

const Preloader = () => {
	document.title = APP_NAME;

	return (
		<div className="suspense-loader">
			<div className="spinner-border text-primary avatar-sm">
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
};

export default Preloader;
