import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Splash from '../components/Splash';

const Layout = () => {
	return (
		<div id="wrapper">
			<Suspense fallback={<Splash />}>
				<Outlet />
			</Suspense>
		</div>
	);
};

export default Layout;
