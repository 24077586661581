import { createSlice } from '@reduxjs/toolkit';

export const electionSlice = createSlice({
	name: 'election',
	initialState: {
		offices: [],
	},
	reducers: {
		setOffices: (state, action) => {
			state.offices = action.payload;
		},
	},
});

export const { setOffices } = electionSlice.actions;

export default electionSlice.reducer;
