import React from 'react';

const Splash = () => {
	return (
		<div className="suspense-loader">
			<div className="spinner-border text-primary avatar-sm">
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
};

export default Splash;
