import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from '../hooks/auth';
import { loginUser, signOut } from '../redux/slices/user';
import { NOT_VISITED, TOKEN_COOKIE } from '../services/constants';
import LocalStorage from '../services/storage';
import { fetchSettings, setSettings } from '../redux/slices/setting';
import { setOffices } from '../redux/slices/election';

const storage = new LocalStorage();

const AuthProvider = ({ children }) => {
	const user = useSelector(state => state.user.account);

	const dispatch = useDispatch();

	const setTokens = data => {
		dispatch(loginUser(data.user));
		dispatch(setOffices(data.user.offices));

		if (data.access_token) {
			storage.setItem(TOKEN_COOKIE, data.access_token);

			// fetch settings
			setTimeout(() => dispatch(fetchSettings()), 5000);
		}
	};

	const logoutUser = () => {
		storage.removeItem(TOKEN_COOKIE);
		storage.removeItem(NOT_VISITED);
		dispatch(signOut());
		dispatch(setSettings([]));
		dispatch(setOffices([]));
	};

	return (
		<AuthContext.Provider value={{ user, setAuthUser: setTokens, logoutUser }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
