import { BASE_API } from './constants';

// authentication
export const LOGIN_API = `${BASE_API}/auth/login`;
export const USER_API = `${BASE_API}/auth/user`;
export const SET_PASSWORD_API = `${BASE_API}/auth/set-password/:id`;
export const FORGOT_PASSWORD_API = `${BASE_API}/auth/forgot-password`;
export const CHECK_PASSWORD_TOKEN_API = `${BASE_API}/auth/check-token/:token`;
export const RESET_PASSWORD_API = `${BASE_API}/auth/reset-password`;
export const LOGOUT_API = `${BASE_API}/auth/logout`;

// settings
export const FETCH_SETTINGS_API = `${BASE_API}/settings`;

// election candidates
export const FETCH_CANDIDATES_API = `${BASE_API}/candidates`;

// election
export const CAST_VOTE_API = `${BASE_API}/vote`;
